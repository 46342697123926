import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/button/Button";
import ErrorMessage from "../components/error-message/ErrorMessage";

import "./SignUp.css";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";

const SignUp = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const [userData, setUserData] = useState({
    name: "",
    date: "",
    email: "",
    phone: "",
    pin: "",
    pinRepeat: "",
  });
  const [messageError, setMessageError] = useState("");

  useEffect(() => {
    console.log(userData);
  }, [messageError]);

  const chekEnter = async () => {
    const validMail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex =
      /^(\+?\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    const validDate = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;


    if (userData.name.length < 5 || userData.name.length > 40) {
      setMessageError("Incorrect name");
    } else if (validDate.test(userData.date) === false) {
      setMessageError("Incorrect date");
    } else if (!validMail.test(userData.email)) {
      setMessageError("Incorrect email");
    } else if (!phoneRegex.test(userData.phone)) {
      setMessageError("Incorrect phone");
      // /^\d+$/.test(userData.pin)
      setMessageError("Incorrect pin");
    } else if (userData.pin !== userData.pinRepeat) {
      console.log(userData.pin, userData.pinRepeat);

      setMessageError("Incorrect repeat pin");
    } else {
      axios
        .post("https://api.walletuah.com/api/register", {
          birth_date: String(userData.date),
          email: String(userData.email),
          full_name: String(userData.name),
          phone: String(userData.phone),
          pin: String(userData.pin),
          telegramid: String(window.Telegram.WebApp.initDataUnsafe?.user?.id)
            ? String(window.Telegram.WebApp.initDataUnsafe?.user?.id)
            : "1234567890",
          // "telegramid": "45364561231"
        })
        .then((response) => {
          console.log(response.data);
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("telegramid", response.data.user.telegramid);
          localStorage.setItem("userid", response.data.user.id);
          navigate("/home");
        })
        .catch((error) => {
          console.log(error.response.data.error);
          console.log(error.response);
          alert(JSON.stringify(error.response));
          enqueueSnackbar(error.response.data.error, {
            variant: "error",
            className: "snackerror",
          });
        });
    }
  };

  const changeInputDate = (e) => {
    setUserData({ ...userData, date: e.target.value });
  };

  document.addEventListener("keydown", (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  });

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/\D/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength <= 3) return phoneNumber;
    if (phoneNumberLength <= 6)
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    if (phoneNumberLength <= 10)
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6)}`;
    return phoneNumber; // Просто возвращает строку без дополнительного форматирования
  };

  return (
    <div className="SignUp">
      <ErrorMessage textError={messageError} />
      <main>
        <h4>{t("sign-up.reg-new-user")}</h4>

        <div className="inputs">
          <input
            id="text"
            type="text"
            name="name"
            placeholder={t("sign-up.input-name")}
            onChange={(e) =>
              setUserData({ ...userData, [e.target.name]: e.target.value })
            }
          />
          <div className="input-date">
            <input
              id="date-text"
              type="text"
              placeholder={t("sign-up.input-date")}
              onChange={changeInputDate}
              value={userData.date}
            />
            <input type="date" onChange={changeInputDate} />
          </div>

          <input
            id="email"
            type="email"
            name="email"
            placeholder={t("sign-up.input-mail")}
            onChange={(e) =>
              setUserData({ ...userData, [e.target.name]: e.target.value })
            }
          />
          <input
            id="tel"
            type="tel"
            name="phone"
            placeholder={t("sign-up.input-phone")}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, ""); // Убираем все нечисловые символы
              if (value.length <= 20) {
                // Ограничиваем длину до 20 цифр
                setUserData({ ...userData, [e.target.name]: value });
              }
            }}
            value={formatPhoneNumber(userData.phone)} // Применяем форматирование
          />
          <input
            id="password"
            type="text"
            name="pin"
            maxLength="4"
            placeholder={t("sign-up.input-pin")}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, ""); // Убираем все нечисловые символы
              if (value.length <= 4) {
                setUserData({ ...userData, [e.target.name]: value });
              }
            }}
            value={userData.pin}
          />
          <input
            id="password-repeat"
            type="text"
            name="pinRepeat"
            maxLength="4"
            placeholder={t("sign-up.input-pin-repeat")}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, ""); // Убираем все нечисловые символы
              if (value.length <= 4) {
                setUserData({ ...userData, [e.target.name]: value });
              }
            }}
            value={userData.pinRepeat}
          />
        </div>
        <Button onClick={() => chekEnter()} text={t("sign-up.reg-button")} />
      </main>
    </div>
  );
};

export default SignUp;
