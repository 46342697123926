import React, { useEffect, useState } from 'react'
import AppNav from '../components/app-nav/AppNav'
import PushMessage from '../components/push-message/PushMessage'
import Transactions from '../home/Transactions'
import sort from "../../img/Sort.svg";
import './AllTransaction.css'
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const AllTransaction = () => {
    const a = axios
    const {t} = useTranslation()
    const [AllTransactions, setAllTransactions] = useState([])
    const [countMoney, setCountMoney] = useState(0);
    const [balanceHistory, setBalanceHistory] = useState([]);
    const [counterMoney, setCounterMoney] = useState(0);
    const [transactions, setTransactions] = useState([])

    const getAllTransactionsHandle = () => {
        axios.get(`https://api.walletuah.com/api/transactions?user_id=${localStorage.getItem("userid")}`)
            .then((response) => {
                // alert(JSON.stringify(response.data));
                setTransactions(response.data == null ? [] : response.data);
            })
        axios.get(`https://api.walletuah.com/api/balance/history?user_id=${localStorage.getItem("userid")}`)
            .then((response) => {
                // alert(JSON.stringify(response.data));
                setBalanceHistory(response.data == null ? [] : response.data.filter(item => !item.action.startsWith("transaction")))
            })
    }
    useEffect(() => {
        getAllTransactionsHandle();
    }, [])

    useEffect(() => {
        let allTransactions = [];

        if (balanceHistory != null) {
            const formattedBalances = balanceHistory.map(item => ({
                action: item.action,
                amount: parseFloat(item.action.match(/[\d.]+/)[0]),
                user_id: item.user_id,
                currency_code: item.currency_code,
                isTransaction: false,
                time: item.updated_at // для сортировки
            }));
            allTransactions = [...allTransactions, ...formattedBalances];
        }

        if (transactions != null) {
            const formattedTransactions = transactions.map(item => ({
                ...item,
                isTransaction: true,
                time: item.date // для сортировки
            }));

            const filteredTransactions = Object.values(
                formattedTransactions.reduce((acc, transaction) => {
                    const {transaction_id, version} = transaction;
                    // Если transaction_id ещё не добавлен или версия выше, обновляем запись
                    if (!acc[transaction_id] || acc[transaction_id].version < version) {
                        acc[transaction_id] = transaction;
                    }
                    return acc;
                }, {})
            );

            allTransactions = [...allTransactions, ...filteredTransactions];
        }

        // Сортировка и добавление в state
        if (allTransactions.length > 0) {
            setAllTransactions(allTransactions.sort((a, b) => new Date(a.time) - new Date(b.time)).reverse());
        }

    }, [balanceHistory, transactions]);
    useEffect(() => {
        console.log(AllTransactions);
    }, [AllTransactions])
    useEffect(() => {
        const numberpPlus = Math.round(countMoney / 100 * 1);

        const animateCounter = async () => {
            while (counterMoney < countMoney) {
                setCounterMoney(prev => {
                    const nextValue = prev + numberpPlus;
                    return nextValue >= countMoney ? countMoney : nextValue;
                });

                await new Promise(resolve => setTimeout(resolve, 2));
            }
        };

        animateCounter();

        return () => setCounterMoney(countMoney);
    }, [countMoney]);

  return (
    <div className='AllTransaction'>
        <PushMessage msg={t("allTrans.text")} />

        <div className="transWrapper">
            <div className="transactions">
                <div className="info">
                    <p className="last-transactions">{t("home.last-transfer")}</p>
                    <div className="sort">
                        <p>{t("home.sorted")}</p>
                        <img src={sort} alt=""/>
                    </div>
                </div>

                <Transactions listTransfers={AllTransactions}/>

            </div>
        </div>

    </div>
  )
}

export default AllTransaction