import React, {useEffect, useState} from 'react'
import PushMessage from '../components/push-message/PushMessage'
import ErrorMessage from '../components/error-message/ErrorMessage'
import Button from '../components/button/Button'
import AppNav from '../components/app-nav/AppNav'
import './Transfer.css'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from "notistack";


const Transfer = () => {
    const { t } = useTranslation();
    // const [errorMsg, setErrorMsg] = useState("")
    const [transferData, setTransferData] = useState({id: "", sum: ""})
    const navigate = useNavigate()

    const [buttonEnabled, setButtonEnabled] = useState(false)

    const { enqueueSnackbar } = useSnackbar();
    const currencyCode = localStorage.getItem('currencyCode');

    const [usdRate, setUsdRate] = useState(40)
    useEffect(() => {
        axios.get("https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json")
            .then((response) => {
                setUsdRate(response.data.find(currency => currency.cc === "USD").rate);
            })
    }, [])

    const sendMain = () => {
        setButtonEnabled(true)
        axios.post("https://api.walletuah.com/api/transaction/create", {
            "amount": Number(currencyCode == "UAH" ? transferData.sum : (transferData.sum * usdRate)),
            "currency_code": "UAH",
            // "currency_code": localStorage.getItem("currencyCode"),
            "receiver_id": Number(transferData.id),
            "sender_id": Number(localStorage.getItem("userid"))
        })
            .then((r) => {
                console.log(r);
                enqueueSnackbar("ok", {
                    variant: "success", className: "snacksuccess"
                });
                setButtonEnabled(false)
                navigate(`/receipt?a=${transferData.sum}&c=${currencyCode}&u=${r.data.transaction_id}&d=${r.data.date}&r=${r.data.receiver_id}&s=${r.data.sender_id}`);
            })
            .catch((error) => {
                console.log(error);
                enqueueSnackbar('Возникла ошибка', {variant: 'error', className: "snackerror" });
                setButtonEnabled(false)
            });
    }


    return (
        <div className='transfer'>
            <PushMessage msg={t("transfer.push-message")}/>
            {/* <ErrorMessage textError={errorMsg}/> */}
            <div className="transfer-data">
                <div className="inputs">
                    <input type="text" placeholder={t("transfer.inputId-placeholder")} onChange={(e)=>setTransferData({...transferData, id: e.target.value})}/>
                    <div className="input-container">
                    {currencyCode == "USD" && transferData.sum != "" && <span className="transfer-rate">{transferData.sum} USD = {(transferData.sum * usdRate).toFixed(2)} UAH</span>}
                    <input type="text" placeholder={`${t("transfer.inputMoney-placeholder")} (${currencyCode})`} onChange={(e)=>setTransferData({...transferData, sum: e.target.value})}/>
                    </div>
                </div>
                <Button disabled={buttonEnabled} text={t("transfer.btn")} onClick={()=>sendMain()}/>
            </div>
            <AppNav block={"transfer"}/>
        </div>
    )
}

export default Transfer