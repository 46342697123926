import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import Transactions from './Transactions'
import sort from "../../img/Sort.svg";
import AppNav from '../components/app-nav/AppNav'
import './Home.css'
import {useNavigate} from "react-router";
import axios from "axios";

const Home = () => {
    const navigate = useNavigate()
    const {t} = useTranslation();
    const currencyCode = localStorage.getItem('currencyCode');

    const [transactions, setTransactions] = useState([])

    const [counterMoney, setCounterMoney] = useState(0);
    const [countMoney, setCountMoney] = useState(0);
    const [balanceHistory, setBalanceHistory] = useState([]);
    const [popUpWindow, setPopUpWindow] = useState(false);
    const [AllTransactions, setAllTransactions] = useState([])
    const [usdRate, setUsdRate] = useState(40)

    const getBalanceHandle = () => {
        axios.get(`https://api.walletuah.com/api/balance/check?user_id=${localStorage.getItem("userid")}`)
            .then((response) => {
                // alert(JSON.stringify(response.data));
                setCountMoney(response.data == null ? 0 : response.data[0].amount)
            })
    }

    const getAllTransactionsHandle = () => {
        axios.get(`https://api.walletuah.com/api/transactions?user_id=${localStorage.getItem("userid")}`)
            .then((response) => {
                // alert(JSON.stringify(response.data));
                setTransactions(response.data == null ? [] : response.data);
            })
        axios.get(`https://api.walletuah.com/api/balance/history?user_id=${localStorage.getItem("userid")}`)
            .then((response) => {
                // alert(JSON.stringify(response.data));
                setBalanceHistory(response.data == null ? [] : response.data.filter(item => !item.action.startsWith("transaction")))
            })
    }
    useEffect(() => {
        getAllTransactionsHandle();
        getBalanceHandle();
    }, [])

    useEffect(() => {
        let allTransactions = [];

        if (balanceHistory != null) {
            const formattedBalances = balanceHistory.map(item => ({
                action: item.action,
                amount: parseFloat(item.action.match(/[\d.]+/)[0]),
                user_id: item.user_id,
                currency_code: item.currency_code,
                isTransaction: false,
                time: item.updated_at // для сортировки
            }));
            allTransactions = [...allTransactions, ...formattedBalances];
        }

        if (transactions != null) {
            const formattedTransactions = transactions.map(item => ({
                ...item,
                isTransaction: true,
                time: item.date // для сортировки
            }));

            const filteredTransactions = Object.values(
                formattedTransactions.reduce((acc, transaction) => {
                    const {transaction_id, version} = transaction;
                    // Если transaction_id ещё не добавлен или версия выше, обновляем запись
                    if (!acc[transaction_id] || acc[transaction_id].version < version) {
                        acc[transaction_id] = transaction;
                    }
                    return acc;
                }, {})
            );

            allTransactions = [...allTransactions, ...filteredTransactions];
        }

        // Сортировка и добавление в state
        if (allTransactions.length > 0) {
            setAllTransactions(allTransactions.sort((a, b) => new Date(a.time) - new Date(b.time)).reverse());
        }

    }, [balanceHistory, transactions]);
    useEffect(() => {
        console.log(AllTransactions);
    }, [AllTransactions])
    useEffect(() => {
        const numberpPlus = Math.round(countMoney / 100 * 1);

        const animateCounter = async () => {
            while (counterMoney < countMoney) {
                setCounterMoney(prev => {
                    const nextValue = prev + numberpPlus;
                    return nextValue >= countMoney ? countMoney : nextValue;
                });

                await new Promise(resolve => setTimeout(resolve, 2));
            }
        };

        animateCounter();

        return () => setCounterMoney(countMoney);
    }, [countMoney]);
    useEffect(() => {
        axios.get("https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json")
            .then((response) => {
                setUsdRate(response.data.find(currency => currency.cc === "USD").rate);
            })
    })

    const navigateTo = (href) => {
        if (window?.Telegram?.WebApp?.HapticFeedback) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred("rigid");
        }
        navigate(href);
    }
    

    return (
        <div className="home">
            <div className="wrapperInfoMessage" style={{visibility: popUpWindow ? "visible" : "hidden" }}>
                <div className={popUpWindow ? "infoMessage infoMessageActive" : "infoMessage"}>
                    <div className="headline">
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none" onClick={()=> setPopUpWindow(false)}>
                            <g clip-path="url(#clip0_1_1119)">
                                <path d="M22.289 3.80464C21.0827 2.59609 19.6497 1.63767 18.0721 0.984388C16.4944 0.331107 14.8033 -0.00416618 13.0958 -0.00218857C5.91548 -0.00218857 0.0947266 5.81856 0.0947266 12.9989C0.0947266 16.5891 1.55073 19.8401 3.90373 22.1931C5.10998 23.4017 6.54304 24.3601 8.12065 25.0134C9.69827 25.6667 11.3894 26.002 13.0969 26C20.2772 26 26.098 20.1792 26.098 12.9989C26.098 9.40873 24.642 6.15764 22.289 3.80464ZM20.6185 20.5172C19.6314 21.5059 18.4589 22.29 17.1682 22.8247C15.8775 23.3593 14.494 23.634 13.0969 23.6329C7.22198 23.6329 2.45964 18.8706 2.45964 12.9956C2.45854 11.5986 2.73319 10.215 3.26784 8.92433C3.80249 7.63362 4.58663 6.46113 5.57531 5.47406C6.56225 4.48551 7.73457 3.70145 9.02508 3.1668C10.3156 2.63216 11.6989 2.35744 13.0958 2.35839C18.9696 2.35839 23.732 7.12073 23.732 12.9946C23.7329 14.3914 23.4582 15.7748 22.9236 17.0653C22.3889 18.3558 21.6049 19.5281 20.6163 20.5151L20.6185 20.5172Z" fill="#F3F3F3" fill-opacity="0.4"/>
                                <path d="M14.442 13.4716L17.7941 10.0998C17.9594 9.91853 18.0488 9.68 18.0435 9.43403C18.0382 9.18805 17.9386 8.95365 17.7656 8.77973C17.5926 8.60582 17.3595 8.50584 17.115 8.50067C16.8704 8.49549 16.6334 8.58552 16.4532 8.75197L16.4541 8.75109L13.102 12.1229L9.74997 8.75109C9.56974 8.58477 9.33261 8.49489 9.08808 8.50022C8.84354 8.50556 8.61051 8.60569 8.43762 8.77971C8.26472 8.95374 8.16533 9.18821 8.16019 9.43419C8.15504 9.68017 8.24455 9.91863 8.41001 10.0998L8.40914 10.0989L11.7612 13.4708L8.40914 16.8426C8.31483 16.9296 8.239 17.0349 8.18622 17.1522C8.13344 17.2695 8.1048 17.3963 8.10203 17.5251C8.09926 17.6538 8.1224 17.7817 8.17008 17.9012C8.21775 18.0207 8.28897 18.1292 8.37945 18.2203C8.46992 18.3114 8.57778 18.3831 8.69653 18.4311C8.81527 18.4791 8.94246 18.5025 9.07042 18.4998C9.19838 18.4971 9.32448 18.4684 9.44111 18.4153C9.55775 18.3623 9.66251 18.2861 9.7491 18.1913L9.74997 18.1904L13.102 14.8186L16.4541 18.1904C16.5406 18.2853 16.6453 18.3616 16.7619 18.4147C16.8785 18.4678 17.0046 18.4966 17.1326 18.4994C17.2605 18.5021 17.3877 18.4789 17.5065 18.4309C17.6253 18.383 17.7332 18.3113 17.8237 18.2203C17.9143 18.1293 17.9856 18.0208 18.0333 17.9014C18.0811 17.7819 18.1043 17.654 18.1016 17.5253C18.0989 17.3965 18.0703 17.2697 18.0176 17.1524C17.9649 17.0351 17.8892 16.9297 17.7949 16.8426L17.7941 16.8417L14.442 13.4716Z" fill="#F3F3F3"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1_1119">
                                    <rect width="26" height="26" fill="white" transform="translate(0.101868)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <h2>{t("home.dearUser")}</h2>
                    <p>
                        {t("home.text1")}
                    </p>
                    <p>
                        {t("home.text2")}<br />
                        {t("home.text3")}
                    </p>
                    <p>
                       {t("home.text4")}<br />
                       {t("home.text5")}
                    </p>
                </div>
            </div>
            <AppNav block="home"/>
            <main>
                {/*  balance-block && transactions */}
                <div className="balance">
                    <h2>{t("home.my-balance")}</h2>

                    <div className="money">
                        <p className="my-money">{currencyCode == "UAH" ? "₴" : "$"} {currencyCode == "UAH" ? counterMoney : (counterMoney / usdRate).toFixed(2)}</p>
                        {/*<p className="interest">+96.11%</p>*/}
                    </div>

                    <div className="control-balance">
                        <button id="deposit" onClick={() => navigateTo("/payment")}>
                            <svg viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M2.44466 9C2.44466 5.46447 2.44466 3.6967 3.54301 2.59835C4.64136 1.5 6.40912 1.5 9.94466 1.5C13.4802 1.5 15.248 1.5 16.3463 2.59835C17.4447 3.6967 17.4447 5.46447 17.4447 9C17.4447 12.5355 17.4447 14.3033 16.3463 15.4016C15.248 16.5 13.4802 16.5 9.94466 16.5C6.40912 16.5 4.64136 16.5 3.54301 15.4016C2.44466 14.3033 2.44466 12.5355 2.44466 9ZM9.94466 4.6875C10.2553 4.6875 10.5072 4.93934 10.5072 5.25V9.142L11.7969 7.85225C12.0166 7.63258 12.3727 7.63258 12.5924 7.85225C12.8121 8.07192 12.8121 8.42808 12.5924 8.64775L10.3424 10.8977C10.2369 11.0032 10.0938 11.0625 9.94466 11.0625C9.79547 11.0625 9.6524 11.0032 9.54691 10.8977L7.29691 8.64775C7.07724 8.42808 7.07724 8.07192 7.29691 7.85225C7.51658 7.63258 7.87273 7.63258 8.0924 7.85225L9.38216 9.142V5.25C9.38216 4.93934 9.63399 4.6875 9.94466 4.6875ZM6.94466 12.1875C6.634 12.1875 6.38216 12.4393 6.38216 12.75C6.38216 13.0607 6.634 13.3125 6.94466 13.3125H12.9447C13.2553 13.3125 13.5072 13.0607 13.5072 12.75C13.5072 12.4393 13.2553 12.1875 12.9447 12.1875H6.94466Z"
                                      fill="#E6E0E9"/>
                            </svg>
                            <p>{t("home.btn-deposit")}</p>
                        </button>
                        <button id="removal" onClick={() => setPopUpWindow(true)}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8087 7.13257C12.8087 9.76615 12.8087 11.0829 11.9906 11.9011C11.1724 12.7192 9.85563 12.7192 7.22205 12.7192C4.58847 12.7192 3.27168 12.7192 2.45354 11.9011C1.63539 11.0829 1.63539 9.76615 1.63539 7.13257C1.63539 4.499 1.63539 3.18221 2.45354 2.36406C3.27168 1.54591 4.58847 1.54591 7.22205 1.54591C9.85563 1.54591 11.1724 1.54591 11.9906 2.36406C12.8087 3.18221 12.8087 4.499 12.8087 7.13257ZM7.22205 10.3449C6.99065 10.3449 6.80305 10.1573 6.80305 9.92591L6.80305 7.0268L5.84233 7.98752C5.6787 8.15115 5.41341 8.15115 5.24978 7.98752C5.08615 7.82389 5.08615 7.55859 5.24978 7.39496L6.92577 5.71896C7.00435 5.64039 7.11093 5.59624 7.22205 5.59624C7.33318 5.59624 7.43975 5.64039 7.51833 5.71896L9.19433 7.39496C9.35796 7.55859 9.35796 7.82389 9.19433 7.98752C9.0307 8.15115 8.7654 8.15115 8.60177 7.98752L7.64105 7.0268L7.64105 9.92591C7.64105 10.1573 7.45346 10.3449 7.22205 10.3449ZM9.45672 4.75824C9.68812 4.75824 9.87572 4.57065 9.87572 4.33924C9.87572 4.10784 9.68812 3.92024 9.45672 3.92024H4.98739C4.75598 3.92024 4.56839 4.10784 4.56839 4.33924C4.56839 4.57065 4.75598 4.75824 4.98739 4.75824L9.45672 4.75824Z" fill="#E6E0E9" />
                            </svg>
                            <p>{t("home.removal")}</p>
                        </button>
                        <button id="send-money" onClick={() => navigateTo("/transfer")}>
                            <svg viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M1.55731 9C1.55731 5.46447 1.55731 3.6967 2.65566 2.59835C3.75401 1.5 5.52178 1.5 9.05731 1.5C12.5928 1.5 14.3606 1.5 15.459 2.59835C16.5573 3.6967 16.5573 5.46447 16.5573 9C16.5573 12.5355 16.5573 14.3033 15.459 15.4017C14.3606 16.5 12.5928 16.5 9.05731 16.5C5.52178 16.5 3.75401 16.5 2.65566 15.4017C1.55731 14.3033 1.55731 12.5355 1.55731 9ZM9.05731 13.3125C9.36797 13.3125 9.61981 13.0607 9.61981 12.75V8.858L10.9096 10.1477C11.1292 10.3674 11.4854 10.3674 11.7051 10.1477C11.9247 9.92808 11.9247 9.57192 11.7051 9.35225L9.45506 7.10225C9.34957 6.99676 9.2065 6.9375 9.05731 6.9375C8.90813 6.9375 8.76505 6.99676 8.65956 7.10225L6.40956 9.35225C6.18989 9.57192 6.18989 9.92808 6.40956 10.1477C6.62923 10.3674 6.98539 10.3674 7.20506 10.1477L8.49481 8.858V12.75C8.49481 13.0607 8.74665 13.3125 9.05731 13.3125ZM6.05731 5.8125C5.74665 5.8125 5.49481 5.56066 5.49481 5.25C5.49481 4.93934 5.74665 4.6875 6.05731 4.6875H12.0573C12.368 4.6875 12.6198 4.93934 12.6198 5.25C12.6198 5.56066 12.368 5.8125 12.0573 5.8125H6.05731Z"
                                      fill="#E6E0E9"/>
                            </svg>
                            <p>{t("home.btn-send")}</p>
                        </button>
                        <button id="transactions" onClick={() => navigateTo("/home")}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <mask id="mask0_116_322" maskUnits="userSpaceOnUse" x="1" y="1" width="12" height="12">
                                    <path d="M11.0682 2.10449H2.68825C2.466 2.10449 2.25285 2.19278 2.09569 2.34994C1.93854 2.50709 1.85025 2.72024 1.85025 2.94249V11.3225C1.85025 11.5447 1.93854 11.7579 2.09569 11.915C2.25285 12.0722 2.466 12.1605 2.68825 12.1605H11.0682C11.2905 12.1605 11.5036 12.0722 11.6608 11.915C11.818 11.7579 11.9062 11.5447 11.9062 11.3225V2.94249C11.9062 2.72024 11.818 2.50709 11.6608 2.34994C11.5036 2.19278 11.2905 2.10449 11.0682 2.10449Z" fill="white" stroke="white" stroke-width="1.48978" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6.04023 9.08786L7.4369 10.2052L9.67157 7.41186M4.0849 4.61853H9.67157M4.0849 6.8532H6.31957" stroke="black" stroke-width="1.48978" stroke-linecap="round" stroke-linejoin="round"/>
                                </mask>
                                <g mask="url(#mask0_116_322)">
                                <path d="M0.174255 0.428467H13.5823V13.8365H0.174255V0.428467Z" fill="#E6E0E9"/>
                                </g>
                            </svg>
                            <p>{t("home.btn-transfer")}</p>
                        </button>
                    </div>
                </div>

                <div className="transactions">
                    <div className="info">
                        <p className="last-transactions">{t("home.last-transfer")}</p>
                        <div className="sort">
                            <p>{t("home.sorted")}</p>
                            <img src={sort} alt=""/>
                        </div>
                    </div>

                    <Transactions listTransfers={AllTransactions}/>

                </div>
                {transactions && (
                    <div className="filler"></div>
                )}
            </main>
        </div>
    );
};

export default Home;