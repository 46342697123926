import React from 'react'
import './Button.css'

const Button = ({text, onClick, disabled}) => {
  const clickHandle = () => {
    if (window?.Telegram?.WebApp?.HapticFeedback) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred("medium");
    }
    onClick();
  }
  return (
    <button disabled={disabled} className='button' onClick={clickHandle}>{text}</button>
  )
}

export default Button