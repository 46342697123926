import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ToggleButtons from "../components/toggle-button/ButtonToggle"
import Button from "../components/button/Button"
import PushMessage from "../components/push-message/PushMessage"
import './Payment.css'
import axios from "axios";
import {useNavigate} from "react-router";
import {useSnackbar} from "notistack";


const Payment = () => {

    const { t } = useTranslation();
    const [sumPayment, setSumPayment] = useState(0);
    const currencyCode = localStorage.getItem("currencyCode") ? localStorage.getItem("currencyCode") : "UAH";

    const { enqueueSnackbar } = useSnackbar();
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const deposithandle = () => {

        setButtonDisabled(true)
        axios.post("https://api.socialsmmclub.space/invoice/deposit", {
            "user_id": Number(localStorage.getItem("userid")),
            "currency_code": "UAH",
            "amount": Number(sumPayment),
        })
            .then((res) => {
                if(res.data.pageUrl != ""){
                    enqueueSnackbar(t("payment.request-sended"), {
                    variant: "success", className: "snacksuccess"
                });
                    window.location.href=res.data.pageUrl;
                }
                if (res.data.pageUrl == ""){
                    enqueueSnackbar(t("payment.request-error"), {
                        variant: "error", className: "snackerror"
                    })
                }
                setButtonDisabled(false)
            })
            .catch((err) => {
                setButtonDisabled(false)
                enqueueSnackbar(t("payment.request-error"), {
                    variant: "error", className: "snackerror"
                })

            })

    }


  return (
    <div className='payment'>
        <PushMessage msg={t("payment.push-message")}/>
        <div className="method-data">
            {/*<div className="method-payment">*/}
                {/*<h4>{t("payment.method-payment")}</h4>*/}
                {/*<ToggleButtons btn1={"Apple Pay"} btn2={"Google Pay"} setParametr={setPaymentMethod}/>*/}
            {/*</div>*/}
            <div className="input-data">
                <input type="number" inputMode="numeric" placeholder={`${t("payment.input-placeholder")} (UAH)`}  onChange={(e)=>{
                    const value = e.target.value.replace(/[^0-9]/g, ""); // Удаляем всё, кроме цифр
                    setSumPayment(value);
                }}
                value={sumPayment != "" ? sumPayment : null}
                />
              <Button disabled={buttonDisabled} onClick={deposithandle} text={t("payment.btn")}/>
            </div>
        </div>

    </div>
  )
}

export default Payment