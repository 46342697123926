import React from 'react'
import usdt from "../../img/usdt.svg";
import uah from "../../img/uah.svg";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";

const Transactions = ({listTransfers}) => {
  const { t } = useTranslation();
  const UID = localStorage.getItem("userid")
  const navigate = useNavigate();
  function formatDate(dateString) {
    const date = new Date(dateString); // Преобразуем строку в объект Date

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }


  return (
    // ~~~
    // transfer = {
    //   "sender_id": 9,
    //   "receiver_id": 9,
    //   "currency_code": "UAH",
    //   "amount": 1
    // }

    <ul>
      {listTransfers === null || listTransfers?.length == 0  ?
          (
              <h3 className="empty">{t("home.empty-transactions")}</h3>
          )
          :
          listTransfers.map((transfer) =>
                transfer.isTransaction ?
                    <li onClick={() => navigate(`/receipt?s=${transfer.sender_id}&r=${transfer.receiver_id}&a=${transfer.amount}&c=${transfer.currency_code}&u=${transfer.transaction_id}&d=${transfer.date}`)}>
                      <div className="info-transfer">
                        <img src={transfer.currency_code === "UAH" ? uah : usdt} alt=""/>
                        <div className="text-info">
                          <h2>{transfer.currency_code}</h2>
                          <p className={transfer.status == "approved" ? "plus" : transfer.status == "pending" ? "pending" : "minus"}>{t(`${transfer.status}`)}</p>
                        </div>
                      </div>
                      <div className="data">
                        <p className={transfer.receiver_id == UID ? "count-money plus" : "count-money minus"}>{transfer.receiver_id == UID ? "+" : "-"}{transfer.amount} {transfer.currency_code}</p>
                        <p className="time">{formatDate(transfer.time)}</p>
                      </div>
                    </li>
                    :
                    <li>
                      <div className="info-transfer">
                        <img src={transfer.currency_code === "UAH" ? uah : usdt} alt=""/>
                        <div className="text-info">
                          <h2>{transfer.currency_code}</h2>
                          <p className={transfer.action.startsWith("deposit") ? "plus" : "minus"}>{transfer.action.startsWith("deposit") ? t("deposit") : t("withdraw")}</p>
                        </div>
                      </div>
                      <div className="data">
                        <p className={transfer.action.startsWith("deposit") ? "count-money plus" : "count-money minus"}>{transfer.action.startsWith("deposit") ? "+" : "-"}{transfer.amount} {transfer.currency_code}</p>
                        <p className="time">{formatDate(transfer.time)}</p>
                      </div>
                    </li>

          )}

    </ul>
  )
}

export default Transactions


