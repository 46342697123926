import React from 'react'
import './Receipt.css'
import PushMessage from '../components/push-message/PushMessage'
import { useTranslation } from 'react-i18next'
import {ReactComponent as Logo} from '../../img/Logo.svg'

const Receipt = () => {

    const formatDate = (dateString)  => {
        const date = new Date(dateString);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
        const year = date.getFullYear().toString(); // Берем последние 2 цифры года
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    };

    const {t} = useTranslation()
    const url = new URL(document.location.href);
    let amount = (url.searchParams.get('a'));
    let sender = (url.searchParams.get('s'));
    let receiver = (url.searchParams.get('r'));
    let cur = (url.searchParams.get('c'));
    let tUID = (url.searchParams.get('u'));
    let date = (url.searchParams.get('d'));
  return (
    <div className='Receipt'>
        <PushMessage msg={t("receipt.receiptMsg")} />
        <div className="ReceiptWrapper">
            <div className="ReceiptBlock">
                {/*<div className="adress">*/}
                {/*    <h2>COINBOXUA</h2>*/}
                    {/*<p>Україна, Київська область, м. Київ, */}
                    {/*вул. Теодора Рузвельта, 9</p>*/}
                    {/*<h2>{t("receipt.IPN")}: 76341982</h2>*/}
                {/*</div>*/}
                {/*<hr />*/}
                <div className="receiptId">
                    <p>{t("receipt.receiptText")} #{tUID}</p>
                    <h2>{t("receipt.moneyTransfer")}</h2>
                </div>
                <hr />
                <div className="ReceiptData">
                    <p>{t("receipt.INsender")}: <span>{sender}</span></p>
                    <p>{t("receipt.INrecipient")}: <span>{receiver}</span></p>
                    <p>{t("receipt.sum")}: <span>{amount} {cur}</span></p>
                    <p>{t("receipt.date")}: <span>{formatDate(date)}</span></p>
                </div>
                <hr />

                <div className="qr">
                    <Logo/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Receipt