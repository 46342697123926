import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PushMessage from "../components/push-message/PushMessage";
import ToggleButton from "../components/toggle-button/ButtonToggle";
import Button from "../components/button/Button";
import AppNav from "../components/app-nav/AppNav";
import {useSnackbar} from "notistack";
import { ReactComponent as Copy } from '../../img/copy.svg'
import "./Settings.css";
import {useNavigate} from "react-router";

const Settings = () => {
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const currentLanguage = localStorage.getItem("i18nextLng") == "ua" ? 0 : 1;
    const currentCurrency = localStorage.getItem("currencyCode") == "UAH" ? 0 : 1;
    const [language, setLanguage] = useState(currentLanguage) // 0 - ua, 1 - ru
    const [currency, setCurrency] = useState(currentCurrency) // 0 - uah, 1 - usdt

    const userId = localStorage.getItem("userid");

    useEffect(()=>{
        i18n.changeLanguage(language === 0 ? "ua" : "ru")
    },[language])
    useEffect(()=>{
        localStorage.setItem("currencyCode", currency == 0 ? "UAH" : "USD");
    },[currency])

    return (
        <div className="settings">
            <AppNav block="setting" />
            <PushMessage msg={t("settings.push-msg")} />
            <div className="setting">
                {/* <h4 className="copyPin" onClick={()=> { navigator.clipboard.writeText(userId).then(() => {alert('Текст скопирован: ' + userId)})} }>{t("settings.my-id")} <Copy/></h4> */}
                <div className="copyPin">
                    <h4>Ваш id {userId}</h4>
                    <Button onClick={()=> {
                        if (window?.Telegram?.WebApp?.HapticFeedback) {
                            window.Telegram.WebApp.HapticFeedback.impactOccurred("soft");
                        }
                        navigator.clipboard.writeText(userId)
                            .then(() => {
                                enqueueSnackbar('Текст скопирован: ' + userId, {
                                    variant: "success", className: "snacksuccess"}
                                )})
                        // e.target.classList.add("btn-active")
                    }}
                            text={"Копировать"}/>
                </div>
                <div className="language">
                    <h4>{t("settings.language-app")}</h4>
                    <ToggleButton btn1={t("settings.ukr")} btn2={t("settings.ru")} defaultActive={currentLanguage} setParametr={setLanguage} />
                </div>
                <div className="currency">
                    <h4>{t("settings.current-balance")}</h4>
                    <ToggleButton btn1={t("settings.uah")} btn2={t("settings.usd")} defaultActive={currentCurrency} setParametr={setCurrency} />
                </div>
            </div>
            <div className="text-setting">
                <h4 onClick={() => navigate("/change-pin")}>{t("settings.change-pin")}</h4>
                <h4>{t("settings.support-service")}</h4>
            </div>
        </div>
    );
};

export default Settings;